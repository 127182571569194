/**
 * 17Lands metadata to retrieve Lords of Limited card ratings/tiers.
 * 
 * The 17Lands JSON data URL for tier lists differs from the web application view for car tiers.
 * For example:
 *  - https://www.17lands.com/tier_list/c2413f72f9474a3880699b60d5f0f419
 *  - https://www.17lands.com/card_tiers/data/c2413f72f9474a3880699b60d5f0f419
 */
export const SET_RATINGS = Object.freeze({
  iko: {
    tier_list_id: "c2413f72f9474a3880699b60d5f0f419"
  },
  m21: {
    tier_list_id: "497f158ad62346c083986284510a91ff"
  },
  znr: {
    tier_list_id: "b46772553e474a5fa1d9968e99b32054"
  },
  khm: {
    tier_list_id: "f756fec70d954ef69f1739bf15294598"
  },
  stx: {
    tier_list_id: "22e32f8ef7584145bd1d3ffda2fd3f1d"
  },
  afr: {
    tier_list_id: "1b78f08a0eb34616b1bdf6adb2509399"
  },
  mid: {
    tier_list_id: "1601274276dd49a6861cf1cf6a91a08e"
  },
  vow: {
    tier_list_id: "3f48592066f840dfb544c50917f67426"
  },
  neo: {
    tier_list_id: "58d9427911c74212841c04e223556395"
  },
  snc: {
    tier_list_id: "581a3392d643426fb69c069f8a82c726"
  },
  hbg: {
    tier_list_id: "d1b3e785450c4c9bb17d08e43ed95d45"
  },
  dmu: {
    tier_list_id: "01bcdde0b88a4a1bba5cbaeda46f0056"
  },
  bro: {
    tier_list_id: "f10630aa987e41f58e46c5559f40b93b"
  },
  one: {
    tier_list_id: "61b7f1a817d74e73b63fa76fbfee19d6"
  },
  sir: {
    tier_list_id: "8d8ce66bd3be451990acab8be7c540a8"
  },
  mom: {
    tier_list_id: "0d993be4656341f5b373965ab7a6fae5"
  },
  ltr: {
    tier_list_id: "de6f838b82ec4bf98cce2c6500432cc9"
  },
  woe: {
    tier_list_id: "ac41ffe4c3b742eea36cdfc2e074f200"
  },
  lci: {
    tier_list_id: "870a65696eec48fc89c7b1f009e09efb"
  },
  mkm: {
    tier_list_id: "399d96a213b44f87bdd2a2c915d6a15a"
  },
  otj: {
    tier_list_id: "6af8b740d63e432b93da53aed00bc091"
  },
  mh3: {
    tier_list_id: "59a12f331f7d4bf5949f933110202d61"
  },
  blb: {
    tier_list_id: "da50517348024cc88105f98ce52c52f3"
  },
  dsk: {
    tier_list_id: "292091ac82554707928df16d509479ef"
  },
  fdn: {
    tier_list_id: "c9d4b680c8d243c7b5827e8559c2c05a"
  },
  dft: {
    tier_list_id: "3324d17f47ac4bd48fcd984703024860"
  }
});
