/**
 * 17Lands metadata to retrieve Chord_O_Calls card ratings/tiers.
 * 
 * The 17Lands JSON data URL for tier lists differs from the web application view for car tiers.
 * For example:
 *  - https://www.17lands.com/tier_list/ad796ccaf0cc4be08511b0afd7a91a3c
 *  - https://www.17lands.com/card_tiers/data/ad796ccaf0cc4be08511b0afd7a91a3c
 */
export const SET_RATINGS = Object.freeze({
  eld: {
    tier_list_id: "30588ade239246d0ab12393d00dc801a"
  },
  // thb: {},
  iko: {
    tier_list_id: "db593297907e41af93eedd994e26da28"
  },
  // m21: {},
  znr: {
    tier_list_id: "afba2e0abc5a4d878217041c17bd4bef"
  },
  // khm: {},
  stx: {
    tier_list_id: "fa752abf65924737ae6b3e04184ce7d1"
  },
  // afr: {},
  mid: {
    tier_list_id: "ef928c7c17bb4f57b09a75be5daf7df9"
  },
  vow: {
    tier_list_id: "ac2ca9722737412ba0c4c4c4b2e28598"
  },
  neo: {
    tier_list_id: "0b2b04f23e104ddba3501cd009385d60"
  },
  snc: {
    tier_list_id: "8513f3fa48f140c0a4792862f530dea9"
  },
  hbg: {
    tierListId: "d24c2d28b6aa4146912b2ca92c503fe1"
  },
  dmu: {
    tier_list_id: "e12ee0b1fadc4ab7b8de4c3730878a90"
  },
  bro: {
    tier_list_id: "b8d4ba9d1bad49828bfa6371f6b4f09b"
  },
  one: {
    tier_list_id: "959517c42af04b909ddb6456904b7001"
  },
  sir: {
    tier_list_id: "1c8b5da1d6ad4918926635ee80e0ce88"
  },
    mom: {
    tier_list_id: "ad796ccaf0cc4be08511b0afd7a91a3c"
  },
  ltr: {
    tier_list_id: "aa685e825b12489a83f081dec8dc308d"
  },
  woe: {
    tier_list_id: "65a50dee5a70418f89c00913c559b768"
  },
  lci: {
    tier_list_id: "b8dad7059ff24da28da636a1c50da7e8"
  },
  ktk: {
    tier_list_id: "a6346d2850ef45918508db61d057388e"
  },
  mkm: {
    tier_list_id: "a5c17edddaea4680a28126dae2a5178f"
  },
  otj: {
    tier_list_id: "5547622369bc4344b90b1cceabdf84b6"
  },
  mh3: {
    tier_list_id: "14b36e19f924475fb93badb9268174a4"
  },
  blb: {
    tier_list_id: "4bd6e39d4be84943b0025cc77addca94"
  },
  dsk: {
    tier_list_id: "856d115e97b443ce955dcc85ece3dcf5"
  },
  fdn: {
    tier_list_id: "71f4654151a244b281e29d30017f9e63"
  },
  dft: {
    tier_list_id: "4cea645cd96d44bda53eda2c1f080e2a"
  }
});
