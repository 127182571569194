import React, { useCallback, useState } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

import TextInput from "../components/widgets/TextInput";

import { TextCardFilter } from "../services/card-filters";

import './CardTextFilter.css';

/**
 * @returns a component for filtering cards by text, with options for 
 * filtering by card name, card type, and card oracle text.
 */
export default function CardTextFilter({ filter, disabled, onReplace, onRemove }) {
  const [isFilterName, setFilterName] = useState(true);
  const [isFilterType, setFilterType] = useState(true);
  const [isFilterText, setFilterText] = useState(true);
  const [isEmpty, setEmpty] = useState(true);

  // update the isEmpty state when the provided filter changes
  // Using `useDeepCompareEffectNoCheck` for a deep compare of the `filter` object
  useDeepCompareEffectNoCheck(() => {
    // isEmpty when no filter or when the filter's text value is empty
    setEmpty(!filter?.value.trim().length);
    // reset filtering options when the given filter is `undefined`
    if (filter === undefined) {
      setFilterName(true);
      setFilterType(true);
      setFilterText(true);
    }  
  }, /* dependencies = */ [filter]);

  const handleTextSubmit = useCallback((text) => {
    if (text.trim().length) {
      const newFilter = new TextCardFilter(text, isFilterName, isFilterType, isFilterText);
      onReplace(newFilter, filter);
    }
    else onRemove(filter);
  }, /* dependencies = */ [ filter, onReplace, onRemove, isFilterName, isFilterType, isFilterText ]);

  function handleTextChange(text) {
    // turn isEmpty off as soon as the <TextInput> is non-empty
    // but DON'T turn it on as soon as it is empty (wait for `handleTextSubmit`)
    if (text.trim().length) setEmpty(false);
  }

  function handleFilterByName(event) {
    const filterByName = event.target.checked;
    // update the local component state
    setFilterName(filterByName);
    if (filter) {
      // create a new, updated filter and propagate the change
      const newFilter = filter.withFilterByName(filterByName);
      onReplace(newFilter, filter);
    }
  }

  function handleFilterByType(event) {
    const filterByType = event.target.checked;
    // update the local component state
    setFilterType(filterByType);
    if (filter) {
      // create a new, updated filter and propagate the change
      const newFilter = filter.withFilterByType(filterByType);
      onReplace(newFilter, filter);
    }
  }

  function handleFilterByOracleText(event) {
    const filterByText = event.target.checked;
    // update the local component state
    setFilterText(filterByText);
    if (filter) {
      // create a new, updated filter and propagate the change
      const newFilter = filter.withFilterByText(filterByText);
      onReplace(newFilter, filter);
    }
  }

  return <><TextInput placeholder='Filter Cards by Text' 
      className="card-text-filter" 
      disabled={disabled} 
      value={filter?.value} 
      onChange={handleTextChange}
      onSubmit={handleTextSubmit}
    />
    <div className={`card-text-filter-types ${isEmpty ? 'hidden' : ''}`}>
      <label>
        <input type="checkbox" id="card-text-filter-name" 
            disabled={disabled || isEmpty} 
            checked={isFilterName}
            onChange={handleFilterByName}
          />
        Name
      </label>
      <label>
        <input type="checkbox" id="card-text-filter-type" 
            disabled={disabled || isEmpty} 
            checked={isFilterType} 
            onChange={handleFilterByType}
          />
        Type
      </label>
      <label>
        <input type="checkbox" id="card-text-filter-text" 
            disabled={disabled || isEmpty} 
            checked={isFilterText} 
            onChange={handleFilterByOracleText}
          />
        Text
      </label>
    </div>
  </>;

}