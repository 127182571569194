import React from 'react';

import CardTextFilter from './CardTextFilter';

import Switch from '../components/widgets/LabelSwitch';

// eslint-disable-next-line no-unused-vars
import CardFilter, { 
  BargainableCardFilter,
  BargainCardFilter,
  ColorCardFilter,
  EnergyCardFilter,
  FilterType, 
  InstantSpeedCardFilter,
  LessonLearnCardFilter,
  OutlawCardFilter,
  RarityCardFilter, 
  StrictColorCardFilter,
  TypeCardFilter
} from '../services/card-filters';

import { 
  isBargain, 
  isLearn, 
  refersToEnergy, 
  refersToOutlaws}         from '../helpers/card.helpers';
import { getColorElement } from '../helpers/ui.helpers';

export default class Filters extends React.Component {

  render() {
    return (
      <div className="filters">
        <div className="filter-header">
          <span className="filter-title">Sideboard Filters</span>
        </div>
        <div className="filter-body">
          <div className="filter">
            <div className="filter-colors">
              {this.renderFilterColorButton("W", "white")}
              {this.renderFilterColorButton("U", "blue")}
              {this.renderFilterColorButton("B", "black")}
              {this.renderFilterColorButton("R", "red")}
              {this.renderFilterColorButton("G", "green")}
              {this.renderFilterColorButton("C", "colorless")}
            </div>
            {this.maybeRenderStrictFilterButton()}
            <div className="filter-types">
              {this.renderFilterTypeButton("creature", "Creatures")}
              {this.renderFilterTypeButton("artifact", "Artifacts")}
              {this.renderInstantsButton()}
              {this.renderFilterTypeButton("sorcery", "Sorceries")}
              {this.renderFilterTypeButton("enchantment", "Enchantments")}
              {this.renderFilterTypeButton("land", "Land")}
              {this.maybeRenderLessonPlanButton()}
              {this.maybeRenderBargainButtons()}
              {this.maybeRenderOutlawsButton()}
              {this.maybeRenderEnergyButton()}
            </div>
            <div className="filter-rarity">
              {this.renderFilterRarityButton("mythic", "Mythic")}
              {this.renderFilterRarityButton("rare", "Rare")}
              {this.renderFilterRarityButton("uncommon", "Uncommon")}
              {this.renderFilterRarityButton("common", "Common")}
            </div>
            <div className="filter-text">
              {this.renderFilterText()}
            </div>
            <div className="filter-controls">
              <button title="Clear Filters"
                className="filter-clear"
                disabled={this.props.disabled || !this.props.cardfilters.length}
                onClick={this.clearFilters}
              >
                Clear
              </button>
              <Switch
                  className="filter-secondary-switch"
                  disabled={this.props.disabled} 
                  onChange={this.props.activateSecondaryFilter} 
                  checked={this.props.isSecondaryFilterActive}>
                Filter {this.props.secondaryName}
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFilterColorButton = (symbol, color) => {
    const colorLowerCase = color.toLowerCase();
    const classNames = ["color", `filter-${colorLowerCase}`];
    if (this.filterExists(FilterType.Color, symbol)) classNames.push("filter-selected");

    const title = color.charAt(0).toUpperCase() + colorLowerCase.slice(1)
    return (
      <button 
          title={title}
          className={classNames.join(" ")} 
          disabled={this.props.disabled} 
          onClick={(e) => this.toggleColorFilter(symbol)}>
        { getColorElement(symbol, false) }
      </button>
    );
  }

  maybeRenderStrictFilterButton = () => {
    // retreive the active filter colors (excluding colorless)
    const colorFilters = this.filteredColors();

    if (colorFilters.length > 1) {
      // translate the active color filter symbols to the color identifier style pair
      const filteredColors = colorFilters.sort().join('').toLowerCase();
      // determine if the strict color filter is active/selected
      const selected = this.filterExists(FilterType.ColorStrict);

      return <div className="filter-colors-strict">
          <button 
              className={`colors-strict ${selected ? 'filter-selected' : ''}`}
              disabled={this.props.disabled} 
              onClick={(e) => this.toggleStrictColorFilter()}>
            Strict Color Filter
            <i key={`colors-strict`} 
                className={`ms ms-ci ms-ci-${colorFilters.length} ms-ci-${filteredColors}`}></i>
          </button>
        </div>;
    }
  }

  renderFilterTypeButton = (cardType, title, buttonContent) => {
    const classNames = ["type", `filter-${cardType}`];
    if (this.filterExists(FilterType.CardType, cardType)) classNames.push("filter-selected");

    return (
      <button 
          title={title}
          className={classNames.join(" ")} 
          disabled={this.props.disabled} 
          onClick={(e) => this.toggleCardTypeFilter(cardType)}>
        {
          // use button content if provided, otherwise try to use an icon
          buttonContent ? 
            buttonContent : 
            <i className={`ms ms-${cardType.toLowerCase()}`}></i>
        }
      </button>
    );
  }

  renderFilterRarityButton = (cardRarity, title, buttonContent) => {
    const classNames = ["rarity", `filter-${cardRarity}`];
    if (this.filterExists(FilterType.Rarity, cardRarity)) classNames.push("filter-selected");

    return (
      <button 
          title={title}
          className={classNames.join(" ")} 
          disabled={this.props.disabled} 
          onClick={(e) => this.toggleCardRarityFilter(cardRarity)}>
        {
          // use button content if provided, otherwise try to use an icon
          buttonContent ? 
            buttonContent : 
            <i className={`ms ms-rarity rarity-${cardRarity.toLowerCase()}`}></i>
        }
      </button>
    );
  }

  /**
   * Render a filter button based on the specified parameters.
   * 
   * @param {CardFilter.FilterType} filterType the filter type the button will represent
   * @param {String} filterValue the value of the filter (e.g., color, rarity)
   * @param {*} toggleFilterFn the function to call when toggling the filter on/off
   * @param {String} identifier a unique identifier for the button style
   * @param {String} title the title attribute for the button
   * @param {*} buttonContent the optional content to be represented in the button, 
   * otherwise an icon will be used based on the `identifier`
   * @returns the button component to render
   */
  renderFilterButton = (filterType, filterValue, toggleFilterFn, identifier, title, buttonContent) => {
    const classNames = [`filter-${identifier}`];
    if (this.filterExists(filterType, filterValue)) classNames.push("filter-selected");

    return (
      <button 
          title={title}
          className={classNames.join(" ")} 
          disabled={this.props.disabled} 
          onClick={toggleFilterFn}>
        {
          // use button content if provided, otherwise try to use an icon
          buttonContent ? 
            buttonContent : 
            <i className={`ms ms-${identifier.toLowerCase()}`}></i>
        }
      </button>
    );
  }

  renderFilterText = () => {
    const existing = this.props.cardfilters.find(f => f.matches(FilterType.Text))

    return <CardTextFilter
        disabled={this.props.disabled}
        filter={existing}
        onReplace={this.props.replaceFilter}
        // remove any existing text filter
        onRemove={() => this.props.removeFilter(FilterType.Text)}
      />;
  }

  renderInstantsButton = () => {
    return this.renderFilterButton(FilterType.InstantSpeed, /* filterValue = */ undefined, 
      this.toggleInstantSpeedFilter,
      "instant", "Instants", <i className='ms ms-instant'></i>);
  }

  maybeRenderLessonPlanButton = () => {
    // determine if the card pool has any Learn cards (don't show if only Lessons)
    const showLessonLearn = this.props.cards.some(isLearn);
    if (showLessonLearn) {
      return this.renderFilterButton(FilterType.LessonLearn, /* filterValue = */ undefined, 
        this.toggleLessonLearnFilter,
        "lesson", "Lesson Plan", <>Lesson Plan</>);
    }
  }

  maybeRenderBargainButtons = () => {
    // determine if the card pool has any Bargain cards
    const showBargain = this.props.cards.some(isBargain);
    if (showBargain) {
      const bargain = this.renderFilterButton(FilterType.Bargain, /* filterValue = */ undefined, 
        this.toggleBargainFilter,
        "bargain", "Bargains", <>Bargains</>);
      const bargainFodder = this.renderFilterButton(FilterType.Bargainable, /* filterValue = */ undefined, 
        this.toggleBargainableFilter,
        "bargainable", "Fodder", <>Fodder</>);

      return <>{bargain} {bargainFodder}</>;
    }
  }

  maybeRenderOutlawsButton = () => {
    // determine if the card pool has any cards that care about 'outlaws'
    const showOutlaws = this.props.cards.some(refersToOutlaws);
    if (showOutlaws) {
      return this.renderFilterButton(FilterType.Outlaw, /* filterValue = */ undefined, 
        this.toggleOutlawFilter,
        "outlaws", "Outlaws", <>Outlaws</>);
    }
  }

  maybeRenderEnergyButton = () => {
    // determine if the card pool has any cards that mentions 'energy'
    const showEnergy = this.props.cards.some(refersToEnergy);
    if (showEnergy) {
      return this.renderFilterButton(FilterType.Energy, /* filterValue = */ undefined, 
        this.toggleEnergyFilter,
        "energy", "Energy", <><i className={`ms ms-e`}></i>&nbsp;Energy</>);
    }
  }

  clearFilters = () => {
    this.props.cardfilters.forEach(f => this.props.removeFilter(f.type, f.value));
  }

  toggleColorFilter = (symbol) => {
    const existing = this.filterExists(FilterType.Color, symbol);
    const newFilter = existing ? undefined : new ColorCardFilter(symbol);
    this.props.replaceFilter(newFilter, existing);

    // Automatically remove the ColorStrict filter if fewer than 2 color filters
    // will remain active (after removing the current `symbol`).
    // Note: `numColorFilters` is the number of color filters active, the just-removed 
    // filter for the provided `symbol` is not yet removed from `this.props.cardfilters`
    const numColorFilters = this.filteredColors().length;
    if (existing && symbol !== 'C' && numColorFilters <= 2) {
      const strictFilter = this.filterExists(FilterType.ColorStrict);
      this.props.removeFilter(strictFilter);
    }
  }

  toggleStrictColorFilter = () => {
    const existing = this.filterExists(FilterType.ColorStrict);
    const newFilter = existing ? undefined : new StrictColorCardFilter();
    this.props.replaceFilter(newFilter, existing);
  }

  toggleCardTypeFilter = (cardType) =>
    this.toggleFilter(
      FilterType.CardType, 
      /* filterValue = */ cardType, 
      () => new TypeCardFilter(cardType));

  toggleInstantSpeedFilter = () =>
    this.toggleFilter(
      FilterType.InstantSpeed, 
      /* filterValue = */ undefined, 
      () => new InstantSpeedCardFilter());
  
  toggleCardRarityFilter = (cardRarity) =>
    this.toggleFilter(
      FilterType.Rarity, 
      /* filterValue = */ cardRarity, 
      () => new RarityCardFilter(cardRarity));

  toggleLessonLearnFilter = () =>
    this.toggleFilter(
      FilterType.LessonLearn, 
      /* filterValue = */ undefined, 
      () => new LessonLearnCardFilter());

  toggleBargainFilter = () =>
    this.toggleFilter(
      FilterType.Bargain, 
      /* filterValue = */ undefined, 
      () => new BargainCardFilter());

  toggleBargainableFilter = () =>
    this.toggleFilter(
      FilterType.Bargainable, 
      /* filterValue = */ undefined, 
      () => new BargainableCardFilter());

  toggleOutlawFilter = () =>
    this.toggleFilter(
      FilterType.Outlaw, 
      /* filterValue = */ undefined, 
      () => new OutlawCardFilter());
  
  toggleEnergyFilter = () =>
    this.toggleFilter(
      FilterType.Energy, 
      /* filterValue = */ undefined, 
      () => new EnergyCardFilter());
    
  toggleFilter = (filterType, filterValue, createFilterFn) => {
    const existing = this.filterExists(filterType, filterValue);
    const newFilter = existing ? undefined : createFilterFn()
    this.props.replaceFilter(newFilter, existing);
  }

  /**
   * Determines if the specified filter type exists for the (optionally) provided value.
   * 
   * @param {FilterType} type 
   * @param {*} value an optional value to match for the specified filter type.
   * @returns true if a matching card filter is active
   */
  filterExists = (type, value) => this.props.cardfilters.find(f => f.matches(type, value));

  /**
   * @returns the active color filters (excluding colorless)
   */
  filteredColors = () => {
    const colorFilters = this.props.cardfilters
      .filter(f => f.matches(FilterType.Color) && f.value !== "C")
      .map(f => f.value);
    return colorFilters;
  }
}
